import React from "react"
import { FormattedMessage, intlShape } from "../../util/reactIntl"
import { formatMoney } from "../../util/currency"
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from "../../util/types"

import { types as sdkTypes } from "../../util/sdkLoader"

const { Money } = sdkTypes
import css from "./OrderBreakdown.module.css"

const LineItemBasePriceMaybe = (props) => {
  const { lineItems, code, intl } = props
  const isNightly = code === LINE_ITEM_NIGHT
  const isDaily = code === LINE_ITEM_DAY
  const isHourly = code === LINE_ITEM_HOUR

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find((item) => item.code === code && !item.reversal)

  let translationKey = isNightly
    ? "OrderBreakdown.baseUnitNight"
    : isDaily
      ? "OrderBreakdown.baseUnitDay"
      : isHourly
        ? "OrderBreakdown.baseUnitHour"
        : "OrderBreakdown.baseUnitQuantity"
  // let weeklyRate
  let quantity = unitPurchase ? unitPurchase.quantity.toString() : null
  let unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null
  let total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null
  if (unitPurchase.quantity.toNumber() % 7 === 0) {
    let roundedAmount
    const weeklyAmount = Math.ceil(unitPurchase.unitPrice.amount * 7)
      .toString()
      .split("")
      .reverse()
    if (Number.parseInt(weeklyAmount[0]) > 0 && Number.parseInt(weeklyAmount[1]) === 9) {
      const diff = 10 - weeklyAmount[0]
      roundedAmount = Number.parseInt(weeklyAmount.reverse().join("")) + diff
    } else {
      roundedAmount = Math.ceil(unitPurchase.unitPrice.amount * 7)
    }

    const weeklyRate = new Money(roundedAmount, "USD")
    // weeklyRate = new Money(Math.ceil(unitPurchase.unitPrice.amount * 7), "USD")
    translationKey = "OrderBreakdown.baseUnitWeek"
    quantity = unitPurchase.quantity.toNumber() / 7
    unitPrice = formatMoney(intl, weeklyRate)
    const totalPrice = new Money(weeklyRate.amount * quantity, "USD")
    total = formatMoney(intl, totalPrice)
  }

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null
}

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
}

export default LineItemBasePriceMaybe
