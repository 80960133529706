import React, { Component } from "react"
import { func, object, string } from "prop-types"
import classNames from "classnames"

import { injectIntl, intlShape } from "../../../util/reactIntl"
import { propTypes } from "../../../util/types"
import { formatMoney } from "../../../util/currency"
import { ensureListing } from "../../../util/data"
import { types } from "../../../util/sdkLoader"
const { Money } = types

import css from "./SearchMapPriceLabel.module.css"

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing)
    const nextListing = ensureListing(nextProps.listing)
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive
    const hasSameRefreshToken = this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken)
  }

  render() {
    const { className, rootClassName, intl, listing, onListingClicked, isActive, config } = this.props
    const currentListing = ensureListing(listing)
    const { price } = currentListing.attributes
    let formattedPrice
    // Create formatted price if currency is known or alternatively show just the unknown currency.
    formattedPrice =
      price && price.currency === config.currency ? formatMoney(intl, price) : price?.currency ? price.currency : null
    // let weeklyPrice
    if (listing.attributes.publicData.listingType === "rental") {
      let roundedAmount
      const weeklyAmount = Math.ceil(price.amount * 7)
        .toString()
        .split("")
        .reverse()
      if (Number.parseInt(weeklyAmount[0]) > 0 && Number.parseInt(weeklyAmount[1]) === 9) {
        const diff = 10 - weeklyAmount[0]
        roundedAmount = Number.parseInt(weeklyAmount.reverse().join("")) + diff
      } else {
        roundedAmount = Math.ceil(price.amount * 7)
      }

      const weeklyPrice = new Money(roundedAmount, price.currency)
      // weeklyPrice = new Money(Math.ceil(price.amount * 7), "USD")
      formattedPrice =
        price && price.currency === config.currency
          ? formatMoney(intl, weeklyPrice)
          : price?.currency
            ? price.currency
            : null
    }

    const classes = classNames(rootClassName || css.root, className)
    const priceLabelClasses = classNames(css.priceLabel, {
      [css.mapLabelActive]: isActive,
      [css.noPriceSetLabel]: !formattedPrice,
    })
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive })

    return (
      // biome-ignore lint/a11y/useButtonType: <explanation>
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>{formattedPrice}</div>
        <div className={caretClasses} />
      </button>
    )
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
}

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
}

export default injectIntl(SearchMapPriceLabel)
